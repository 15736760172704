* {
  box-sizing: border-box;
}
html {
  font-size: 75%;
}
.App {
  background: #E5E5E5;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}
.container {
  width: 75%;
  min-height: 100vh;
  background: #EBF2FA;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Spartan', sans-serif;
  color: #457B9D;
}
p {
  font-family: 'Open Sans', sans-serif;
  color: #064789;
}
@font-face {
  font-family: 'Meie Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/meiescript/v19/_LOImzDK7erRjhunIspaMjxn.ttf) format('truetype');
}
@font-face {
  font-family: 'Comforter Brush';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/comforterbrush/v3/Y4GTYa1xVSggrfzZI5WMjxRaOz0jwA.ttf) format('truetype');
}

.nav {
  width: 100%;
}
.nav .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  padding: auto;
  align-items: center;
}
.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }
}
.logo {
  width: 50%;
  height: 100%;
  display: flex;
  margin: auto;
  margin-left: 2rem;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .logo {
    display: flex;
    margin: auto;
    width: 85%;
    margin-top: 5%;
  }
}
.logo svg {
  width: 100%;
  height: 100%;
  fill: none;
}
.nav-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding-right: 2rem;
}
.link {
  font-family: 'Spartan', sans-serif;
  font-size: 24px;
  text-decoration: none;
  margin: 0.75rem;
  padding: 1rem;
  color: #457B9D;
  letter-spacing: -0.03em;
  font-weight: bold;
}
.link:hover {
  color: #064789;
  transition: 350ms;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .link {
    font-size: 20px;
  }
}

@media all and (max-width: 1336px) {
  .tablet {
    font-size: 16px;
  }
}
.link-container {
  margin: auto;
  padding: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .link-container {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
    margin: auto;
    padding: auto;
  }
}
.request-button {
  height: 100%;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #064789;
  text-decoration: none;
  color: #FAFAFA;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}
.request-button:hover {
  background-color: #EBF2FA;
  color: #064789;
  transition: 350ms;
}
.upperbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 2rem;
  background-color: #064789;
  margin-bottom: 1rem;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .upperbar {
    display: none;
  }
}
.celedon {
  filter: invert(14%) sepia(70%) saturate(1000%) hue-rotate(167deg) brightness(97%) contrast(60%);
}
.landing {
  width: 100%;
  height: auto;
}
.landing-container {
  width: 100%;
  min-height: 1280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
}
.hero {
  width: 100%;
  min-height: 565px;
  display: flex;
  flex-direction: column;
  background-image: url("../images/hero-image.jpg");
  background-size: cover;
  align-content: flex-start;
  margin: auto;
  padding: auto;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .hero {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.hero-content {
  display: flex;
  flex-direction: column;
  flex-wrap: flex-start;
  justify-content: center;
  margin: auto;
  margin-left: 1rem;
  padding: auto;
  padding-left: 2.5rem;
  min-height: 565px;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .hero-content {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    padding-left: 0;
  }
}
.hero-content h2 {
  font-family: 'Spartan', sans-serif;
  color: white;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 54px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  /* Background Color: Honeydew */
  color: #f1faee;
  text-shadow: 0px 4px 12px rgba(0, 0, 0, 0.8);
  margin-bottom: 1rem;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .hero-content h2 {
    display: flex;
    justify-self: center;
    margin-bottom: 0;
    font-size: 32px;
    line-height: 32px;
    margin-top: 50px;
  }
}
.hero-content p {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 45px;
  display: flex;
  align-items: center;
  letter-spacing: -0.05em;
  /* Background Color: Honeydew */
  color: #f1faee;
  text-shadow: 0px 4px 10px #000000;
  width: 60%;
  text-align: left;
  margin-top: 0;
}

p .large{
  font-size: 2.5rem;
}

p .med {
  font-size: 1.5rem;
}

p .small {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1%;
}

@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .hero-content p {
    text-align: center;
    display: flex;
    justify-content: center;
    justify-self: center;
    margin: auto;
    margin-top: 5%;
    margin-bottom: 0;
    padding: auto;
    width: 100%;
    font-size: 12px;
    line-height: 35px;
  }
}
.hero-content button {
  background: #ffffff;
  border: none;
  width: 50%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: #679436;
  font-family: 'Spartan', sans-serif;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: -0.05em;
  text-align: center;
  text-transform: uppercase;
}
.hero-content button:hover {
  background: #064789;
  transition-duration: 0.3s;
  color: #F1FAEE;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .hero-content button {
    display: flex;
    justify-self: center;
    margin: auto;
    padding: auto;
    margin-top: 5%;
    margin-bottom: 20px;
    font-size: 20px;
  }
}
.email-form {
  width: 90%;
  height: auto;
  margin: auto;
  margin-top: 2rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .email-form {
    width: 90%;
    height: auto;
    margin: auto;
    margin-top: 2rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
  }
}
.vert-divider-blue {
  border: 1px #064789 solid;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .vert-divider-blue {
    display: none;
  }
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .email-form .title {
    font-size: 26px;
  }
}
.input-form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin: 0;
  padding: 0;
  align-self: center;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .input-form {
    display: flex;
    flex-direction: column;
    border: none;
  }
}
input,
textarea {
  width: 40%;
  border: none;
  margin: auto;
  padding: 2rem;
}
input:focus,
textarea:focus {
  outline: none;
  background: #E5E5E5;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  input,
  textarea {
    width: 100%;
    margin-top: 2%;
    border: #457B9D solid 1px;
  }
}
.reverse {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}
.cta_email {
  width: 80%;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .cta_email {
    width: 100%;
  }
}
.email-form input {
  min-height: 71px;
}
.email-form .vert-divider-blue {
  min-height: 71px;
  margin: 0;
}
#a:hover ~ #b {
  opacity: 0;
}
#a {
  width: auto;
}
#b {
  width: auto;
}
form .button {
  width: 20%;
  border: none;
  background: #064789;
  color: #FAFAFA;
  font-family: 'Spartan', sans-serif;
  font-size: 24px;
  font-weight: 600;
}
form .button:hover {
  font-family: 'Spartan', sans-serif;
  background-color: #EBF2FA;
  color: #457B9D;
  transition-duration: 0.3s;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  form .button {
    width: 40%;
    margin: auto;
    min-height: 50px;
    margin-top: 0.5%;
  }
}
.email-form h2 {
  font-family: 'Spartan', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1rem 0rem;
  padding: auto;
  color: #064789;
}
.landing-about {
  width: 88%;
  margin: auto;
  margin-bottom: 2rem;
  margin-top: 2rem;
  padding: auto;
  display: flex;
  justify-content: center;
  background: #c4c4c4;
}
.landing-about-wrapper {
  width: 90%;
  height: auto;
  margin: 2rem;
  padding: auto;
  display: flex;
  justify-content: center;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 1137px) {
  .landing-about-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.landing-about-content {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
.landing-about-content h3 {
  font-family: 'Spartan', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  text-align: center;
  letter-spacing: -0.05em;
  color: #064789;
  margin: 0;
  padding: auto;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .landing-about-content h3 {
    font-size: 24px;
  }
}
.landing-about-content p {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #064789;
  margin: auto;
  margin-top: 0;
  margin-bottom: 3rem;
  padding: auto;
  width: 94%;
  text-align: left;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .landing-about-content p {
    width: 100%;
    margin-top: 10px;
    font-size: 18px;
  }
}
.headshot img {
  max-width: 350px;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .headshot img {
    width: 100%;
  }
}
.landing-about-content button {
  height: 50px;
  width: 500px;
  align-self: center;
  border: none;
  background: #064789;
  color: #F1FAEE;
  font-family: 'Spartan', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: -0.05em;
}
.landing-about-content button:hover {
  background: #F1FAEE;
  color: #064789;
  transition-duration: 350ms;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .landing-about-content button {
    width: 200px;
  }
}
.landing-call-to-action {
  width: 88%;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: auto;
  display: flex;
  justify-content: center;
}
.landing-call-to-action-wrapper {
  width: 90%;
  height: auto;
  margin: 2rem;
  padding: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px), (max-width: 1136px) {
  .landing-call-to-action-wrapper {
    display: flex;
    flex-direction: column;
  }
}
.landing-call-to-action-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .landing-call-to-action-content {
    display: flex;
    flex-direction: column;
    min-height: 350px;
  }
}
.landing-call-to-action-content h3 {
  font-family: 'Spartan', sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  text-align: left;
  letter-spacing: -0.05em;
  margin: 0;
  padding: auto;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .landing-call-to-action-content h3 {
    font-size: 16px;
    margin: auto;
  }
}
.landing-call-to-action-content p {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #064789;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: auto;
  width: 90%;
  text-align: left;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .landing-call-to-action-content p {
    font-size: 12px;
    padding-top: 10px;
  }
}
.service-list {
  display: flex;
  flex-wrap: wrap;
  height: 100px;
  width: 100%;
  padding-left: 30px;
  margin-bottom: 1rem;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .service-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding-top: 10px;
  }
}
.service-list li {
  font-size: 16px;
  font-weight: 400;
  width: 36%;
  height: auto;
  margin: auto;
  margin-left: 4rem;
  margin-right: 1rem;
  margin-bottom: 0;
  margin-top: 0;
  padding: auto;
  padding-top: 0;
  padding-bottom: 0;
  color: #064789;
  text-align: left;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .service-list li {
    margin: auto;
    width: 100%;
    font-size: 12px;
  }
}
.meeting{
  display: flex;
  justify-content: center;
  margin: auto;
}

.meeting img {
  max-width: 600px;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .meeting img {
    max-width: 200px;
  }
}
.landing-call-to-action-content button {
  height: 50px;
  width: 450px;
  align-self: center;
  border: none;
  background: #064789;
  color: #F1FAEE;
  font-family: 'Spartan', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: -0.05em;
  margin: auto;
  margin-right: 5rem;
}
.landing-call-to-action-content button:hover {
  background: #F1FAEE;
  color: #064789;
  border: #064789 solid 1px;
  transition-duration: 0.2s;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .landing-call-to-action-content button {
    width: 200px;
    margin: auto;
    padding: auto;
    margin-top: 5%;
  }
  .landing-call-to-action-content button span {
    font-size: 12px;
  }
}
.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #457B9D;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #457B9D;
  }
}
.footer-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .footer-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.subscribe {
  background-image: url("../images/food-board.jpg");
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .subscribe {
    background-image: url("../images/food-board.jpg");
    min-height: 255px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
}
.subscribe h3 {
  font-size: 36px;
  font-weight: 600;
  font-family: 'Spartan', sans-serif;
  text-transform: uppercase;
  -webkit-text-stroke: 0.5px #4d4d4d93;
  text-shadow: 4px 4px 3px #000000;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 0.5rem;
  padding: auto;
  color: #FAFAFA;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .subscribe h3 {
    font-size: 24px;
  }
}
.subscribe p {
  font-size: 15px;
  font-weight: 600;
  font-family: 'Spartan', sans-serif;
  text-transform: uppercase;
  text-shadow: 4px 4px 3px #000000;
  margin: 5px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: auto;
  color: #FAFAFA;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .subscribe p {
    font-size: 12px;
  }
}
.subscribe form {
  margin: auto;
  margin-top: 1%;
  padding: auto;
  display: flex;
  width: 60%;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .subscribe form {
    height: 50%;
    width: auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.subscribe input {
  width: 80%;
  background: #EBF2FA;
  height: auto;
  border: none;
  margin: auto;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .subscribe input {
    width: 40%;
    margin: auto;
    margin-top: 1%;
    max-height: 50px;
  }
}
.subscribe-btn {
  margin: auto;
  margin-top: 1%;
  width: 125px;
  min-height: 50px;
  font-family: 'Spartan', sans-serif;
  color: #FAFAFA;
  font-size: 18px;
  font-weight: 600;
  border: none;
  background-color: #064789;
}
.subscribe-btn:hover {
  background: #FAFAFA;
  transition-duration: 0.3s;
  border: none;
  color: #064789;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .subscribe-btn {
    width: 40%;
    margin: auto;
    margin-top: 1%;
  }
}
.subscribe-btn p {
  text-shadow: none;
  width: 100%;
  padding: auto;
  margin: auto;
}
.subscribe-btn p:hover {
  color: #457B9D;
  transition: 0.3s;
}
.branding {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 250px;
}

@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px), (max-width: 1336px) {
  .branding {
    max-height: 100px;
  }
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: auto;
  height: 100%;
  width: 100%;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px), (max-width: 1336px) {
  .logo-container {
    margin-right: 5px;
    margin-left: 5px;
  }
}
.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: auto;
  height: 100%;
  width: 100%;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .social-icons {
    width: 300px;
  }
}
.social-icons p {
  color: #EBF2FA;
  font-family: 'Spartan', sans-serif;
  font-weight: normal;
  font-size: 24px;
  margin-right: 1rem;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px), (max-width: 1336px) {
  .social-icons p {
    display: none;
  }
}
.footer-icon {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
@media all and (max-width: 640px), (max-width: 850px) {
  .footer-icon {
    width: auto;
  }
}
@media (max-width: 480px) {
  .footer-icon {
    width: 60%;
  }
}
.email-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: auto;
  padding: auto;
  height: 100%;
  width: 100%;
}
.email-link-wrapper {
  margin: auto;
  margin-right: 2rem;
}
.email-link p {
  color: #EBF2FA;
  font-size: 18px;
  font-family: 'Spartan', sans-serif;
  text-align: right;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .email-link p {
    font-size: 12px;
  }
}
.email-link button {
  height: 50px;
  width: 125px;
  font-family: 'Spartan', sans-serif;
  color: #064789;
  font-size: 18px;
  font-weight: 600;
  border: none;
}
.email-link button:hover {
  background: #064789;
  transition-duration: 0.3s;
  border: none;
  color: #EBF2FA;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .email-link button {
    width: 50px;
    height: 30px;
    font-size: 12px;
  }
}

.vert-divider {
  margin: auto;
  height: 70%;
  border: 2px white solid;
}
.disclosures {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 50px;
  background: #064789;
}
.disclosures p {
  color: #EBF2FA;
  font-family: 'Open Sans', sans-serif;
  font-size: 18px;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px), (max-width: 1336px) {
  .disclosures p {
    font-size: 10px;
  }
}
.copyright {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: auto;
  height: 100%;
  width: 100%;
}
.copyright p {
  width: 90%;
  text-align: left;
  margin: 0;
  padding: 0;
}
.design {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: auto;
  height: 100%;
  width: 100%;
}
.site-map {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: auto;
  height: 100%;
  width: 100%;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .site-map {
    flex-direction: column;
  }
}
.site-map a {
  width: 40%;
  text-align: right;
  color: #EBF2FA;
  text-decoration: none;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
}
.site-map a:hover {
  color: #A5BE00;
  transition-duration: 0.3s;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px), (max-width: 1336px) {
  .site-map a {
    font-size: 10px;
    width: 100%;
    padding-right: 5%;
  }
}
.design a {
  color: #EBF2FA;
  text-decoration: none;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
}
.design a:hover {
  color: #E63946;
  transition-duration: 0.3s;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px), (max-width: 1336px) {
  .design a {
    font-size: 10px;
  }
}
.white-logo svg {
  width: 100%;
  margin: auto;
}
.about {
  margin: 3rem 0rem;
  width: 90%;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .about {
    margin: auto;
  }
}
.upper-third {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .upper-third {
    flex-direction: column;
  }
}
.profile {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .profile {
    flex-direction: column;
    margin: auto;
  }
}
.cropped {
  width: 350px;
  height: 300px;
  object-fit: cover;
  overflow: hidden;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .cropped {
    width: 100%;
  }
}
.cropped img {
  width: 90%;
  margin: 0px 0px 0px 0px;
}
.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 350px;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .text {
    margin: auto;
  }
}
.text h1 {
  color: #064789;
  font-size: 36px;
  margin: auto;
  padding: auto;
  margin-bottom: 0;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .text h1 {
    font-size: 24px;
    margin-top: 20px;
  }
}
.text h3,
h4 {
  color: #064789;
}
.text h3 {
  font-size: 24px;
  letter-spacing: -0.1rem;
  font-weight: 100;
  margin: auto;
  margin-top: 1rem;
  margin-left: 1.6rem;
  margin-bottom: 1rem;
  padding: auto;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .text h3 {
    margin: auto;
  }
}
.text h4 {
  font-size: 16px;
  font-weight: 100;
  width: 70%;
  text-align: left;
  margin: auto;
  padding: auto;
  margin-top: 0rem;
  margin-bottom: 0;
  margin-left: 1.8rem;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .text h4 {
    margin: auto;
    text-align: center;
  }
}
.text h5 {
  color: #064789;
  font-size: 16px;
  font-weight: bold;
  margin: auto;
  padding: auto;
  margin-top: 1rem;
}
.about-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .about-contact {
    width: 100%;
  }
}
.about-contact h2 {
  color: #064789;
  font-size: 24px;
  width: 100%;
  text-align: right;
  margin: 0;
  padding: 0;
  padding-right: 2rem;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .about-contact h2 {
    text-align: center;
    margin-top: 20px;
  }
}
.form {
  margin-right: 1rem;
  margin-top: 0.5rem;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .form {
    margin: auto;
    width: 90%;
  }
}
.form form {
  display: flex;
  flex-direction: row;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .form form {
    flex-direction: column;
    width: 100%;
  }
}
.left-half {
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: auto;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .left-half {
    margin: auto;
    width: 100%;
  }
}
.left-half input {
  width: 200px;
  height: 50px;
  border: #064789 solid 1px;
  border-radius: 5px;
  margin: 0.5rem 0.5rem;
  background-color: #F1FAEE;
}
.left-half input:focus {
  background: #EBF2FA;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .left-half input {
    width: 100%;
  }
}
.right-half {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
  height: auto;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .right-half {
    width: 100%;
  }
}
.right-half textarea {
  width: 200px;
  height: 115px;
  resize: none;
  margin: 0.5rem 0.5rem;
  border-radius: 5px;
  background: #F1FAEE;
  border: #064789 solid 1px;
}
.right-half textarea:focus {
  background: #EBF2FA;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .right-half textarea {
    margin: auto;
    width: 100%;
  }
}
.profile-btn {
  width: 200px;
  height: 50px;
  margin: 0.5rem 0.5rem;
  border-radius: 5px;
  border: #064789 solid 1px;
  background: #064789;
  font-family: 'Spartan', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #FAFAFA;
  transition: 350ms;
}
.profile-btn:hover {
  background: #FAFAFA;
  color: #064789;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .profile-btn {
    margin: auto;
    margin-top: 20px;
    width: 100%;
  }
}
.middle-third {
  width: 100%;
  margin: auto;
  padding: auto;
}
.middle-third h2 {
  text-align: left;
  margin: 0rem 0rem 1.5rem 1.5rem;
  font-size: 28px;
  font-weight: 100;
  letter-spacing: -0.05em;
  color: #064789;
}
.middle-third h3 {
  text-align: left;
  margin: 2rem 0rem 1.5rem 1.5rem;
  font-size: 22px;
  font-weight: 100;
  letter-spacing: -0.05em;
  color: #064789;
}
.middle-third p {
  text-align: left;
  margin: auto;
  padding: auto;
  margin-left: 1.5rem;
  color: #064789;
  font-size: 20px;
}
.bottom-third {
  width: 100%;
  margin: auto;
  padding: auto;
}
.bottom-third a {
  margin: auto;
  padding: auto;
  text-decoration: none;
  color: #064789;
}
.bottom-third h2 {
  margin: auto;
  padding: auto;
  margin-left: 1.5rem;
  text-align: left;
  width: 100%;
}
.about .wrapper hr {
  border: solid 1px #457B9D;
  width: 100%;
  margin: 3rem 0rem;
}
.contact {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0rem;
  padding: auto;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .contact {
    margin: auto;
  }
}
.contact .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding: auto;
}
.contact .wrapper h3 {
  font-size: 36px;
  width: 45%;
  font-weight: 100;
  color: #064789;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .contact .wrapper h3 {
    width: 90%;
    font-size: 24px;
    margin: auto;
  }
}
.contact-form {
  width: 90%;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .contact-form {
    margin: auto;
  }
}
.contact-form form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 300px;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .contact-form form {
    margin: auto;
    height: auto;
  }
}
.halves {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: auto;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .halves {
    flex-direction: column;
    margin: auto;
  }
}
.halves .left-half {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 33%;
  margin: 0rem 1rem;
  height: 100%;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .halves .left-half {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.halves .left-half input {
  width: 100%;
  margin: 0rem 0rem;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .halves .left-half input {
    width: 90%;
    margin: auto;
    margin-top: 20px;
  }
}
.halves .right-half {
  width: 66%;
  margin: 0rem 1rem;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .halves .right-half {
    width: 90%;
    margin: auto;
  }
}
.halves .right-half textarea {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: auto;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .halves .right-half textarea {
    width: 90%;
    margin: auto;
  }
}
.contact-form button {
  width: 195px;
  height: 50px;
  background-color: #064789;
  color: #EBF2FA;
  border: none;
  border-radius: 5px;
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 0rem;
  margin-right: 1rem;
  transition: 350ms;
}
.contact-form button:hover {
  transition: 350ms;
  background: #EBF2FA;
  color: #064789;
  border: solid 1px #064789;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .contact-form button {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.services {
  margin: 2rem 0rem;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .services {
    width: 100%;
    margin: auto;
    padding: auto;
  }
}
.top-half {
  width: 100%;
  margin: auto;
  padding: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 2rem;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .top-half {
    width: 100%;
    margin: auto;
    padding: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 2rem;
  }
}
.top-half .service {
  border: solid 3px #064789;
  width: 50%;
  min-height: 394px;
  background: #EBF2FA;
  margin: 0rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .top-half .service {
    width: 75%;
    margin: auto;
    margin-top: 20px;
  }
}
.top-half .service h2 {
  color: #064789;
  font-weight: bold;
  font-size: 36px;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .top-half .service h2 {
    font-size: 30px;
  }
}
.top-half .service p {
  color: #064789;
  font-size: 20px;
  width: 90%;
  text-align: left;
  margin: auto;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .top-half .service p {
    font-size: 18px;
  }
}
.top-half button {
  width: 75%;
  height: 40px;
  background: #064789;
  color: #EBF2FA;
  border: none;
  border-radius: 5px;
  margin: auto;
  font-family: 'Spartan', sans-serif;
  font-size: 16px;
  font-weight: 600;
  transition: 350ms;
}
.top-half button:hover {
  background: #EBF2FA;
  border: solid 1px #064789;
  color: #064789;
}
.bottom-half {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin: auto;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .bottom-half {
    flex-direction: column;
  }
}
.bottom-half .service {
  border: solid 2px #064789;
  width: 316px;
  height: 260px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #EBF2FA;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .bottom-half .service {
    width: 75%;
    margin-bottom: 25px;
    border: solid #064789 3px;
  }
}
.bottom-half .service h3 {
  font-size: 22px;
  font-weight: bold;
  color: #064789;
  letter-spacing: -0.03em;
  margin: 2rem 0rem;
}
.bottom-half .service p {
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  color: #064789;
  margin: 0rem 4rem;
  width: 75%;
}
.bottom-half button {
  width: 75%;
  height: 40px;
  background: #064789;
  color: #EBF2FA;
  border: none;
  border-radius: 5px;
  margin: auto;
  font-family: 'Spartan', sans-serif;
  font-size: 16px;
  font-weight: 600;
  transition: 350ms;
}
.bottom-half button:hover {
  background: #EBF2FA;
  border: solid 1px #064789;
  color: #064789;
}
.service-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: auto;
}
.service-form form {
  width: 95%;
  margin: auto;
}
.service-form input {
  border: solid 1px #064789;
  border-radius: 5px;
}
.service-form textarea {
  border: solid 1px #064789;
  border-radius: 5px;
  resize: none;
}
.name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.names {
  display: flex;
  flex-direction: column;
}
.names input {
  width: 190px;
  height: 40px;
  margin: 0.5rem 0.5rem;
}
.name textarea {
  width: 484px;
  height: 110px;
  margin: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.message {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.message input {
  width: 66%;
  height: 50px;
  margin: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.message button {
  width: 33%;
  height: 50px;
  background: #064789;
  color: #EBF2FA;
  font-size: 16px;
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  margin: auto;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.message button:hover {
  background: #EBF2FA;
  border: solid 1px #064789;
  color: #064789;
}
.service {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}
.service .wrapper {
  width: 80%;
}
.service .wrapper h1 {
  font-weight: 600;
  font-size: 42px;
  color: #064789;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .service .wrapper h1 {
    font-size: 28px;
  }
}
.service .wrapper .content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .service .wrapper .content {
    flex-direction: column;
  }
}
.service .wrapper .description p {
  font-size: 24px;
  color: #064789;
  width: 80%;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .service .wrapper .description p {
    width: 75%;
    margin: auto;
  }
}
@media all and (max-width: 480px), (max-width: 640px) {
  .service .wrapper .description p {
    font-size: 16px;
  }
}
.service .wrapper .content .description {
  text-align: left;
  width: 100%;
}
.service .wrapper .content .form {
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0;
  padding: 0;
}
.service .wrapper .content .form h3 {
  font-size: 24px;
  color: #064789;
}
.service .wrapper .content .form form {
  display: flex;
  flex-direction: column;
}
.service .wrapper .content .form form input {
  width: 390px;
  height: 50px;
  border: solid 1px #064789;
  border-radius: 5px;
  margin: 0.5rem 0rem;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .service .wrapper .content .form form input {
    width: 100%;
    margin: 0.5rem 0rem;
  }
}
.service .wrapper .content .form form button {
  width: 390px;
  height: 50px;
  background: #064789;
  color: #EBF2FA;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  margin-top: 0.5rem;
}
.service .wrapper .content .form form button:hover {
  background: #EBF2FA;
  color: #064789;
  border: solid 1px #064789;
}
@media all and (max-width: 480px) and (max-width: 640), (max-width: 850px) {
  .service .wrapper .content .form form button {
    width: 100%;
  }
}
.input-form {
  margin: none;
  padding: none;
}
.questionnaire-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
}
.test-contact-form {
  width: 100%;
  height: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.input_area {
  width: 80%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  margin-top: 2%;
  padding: auto;
}
.input_area select {
  width: 100%;
  height: 40px;
}
.input_area input {
  width: 100%;
  height: 50px;
  margin: auto;
  padding: auto;
  border: solid black 1px;
}
.questionnaire-button {
  border: solid 1px black;
}
.input_area p {
  width: 1200px;
}
.questionnaire-button {
  display: inline-flex;
  justify-content: center;
  text-align: center;
  margin-top: 2%;
  font-family: 'Open Sans', sans-serif;
  font-size: 28px;
  line-height: 5px;
  background-color: #064789;
  color: #FAFAFA;
  width: 30%;
  height: 50px;
}
.questionnaire-button:hover {
  background-color: #FAFAFA;
  color: #064789;
  transition: 350ms;
  border: solid 1px #064789;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .questionnaire-button {
    margin-top: 5%;
    max-height: 30px;
    font-size: 20px;
  }
}
.popup-container {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9998;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
}

.close {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}

.close span {
  margin-right: 25px;
  font-size: 25px;
  color: #064789;
}

.close span:hover {
  cursor: pointer;
  color: #679436;
}

.popup {
  background: #EBF2FA;
  width: 50rem;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto;
}

@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .popup {
    width: 90%;
  }
}

.disclaimer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 0;
  width: 100%;
  height: 50px;
}
.disclaimer .col-1 {
  width: 33%;
  height: 50px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  border: none;
  font-size: 12px;
}
.disclaimer .col-2 {
  width: 11%;
  height: 50px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  border: none;
}
.disclaimer .col-2 button {
  width: 100%;
  margin: auto;
  font-size: 12px;
  border: #064789 solid 1px;
  background-color: #064789;
  color: #FAFAFA;
  font-family: 'Spartan', sans-serif;
  transition: 350ms;
}
.disclaimer .col-2 button:hover {
  border: #064789 solid 1px;
  background-color: #EBF2FA;
  color: #457B9D;
}
.disclaimer .col-3 {
  width: 33%;
  height: 50px;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  border: none;
}
.disclaimer .col-3 button {
  width: 50%;
  margin: auto;
  font-size: 12px;
  border: #064789 solid 1px;
  background-color: #064789;
  color: #FAFAFA;
  font-family: 'Spartan', sans-serif;
  transition: 350ms;
}
.disclaimer .col-3 button:hover {
  border: #064789 solid 1px;
  background-color: #EBF2FA;
  color: #457B9D;
}
.disclaimer h1,
p,
a {
  margin: 0;
  padding: 0;
}
.hidden {
  display: none;
}
.consult {
  display: flex;
  margin: auto;
  padding: auto;
  width: 100%;
  margin-bottom: 5rem;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .consult {
    width: 100%;
    margin: auto;
  }
}
.consult .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: auto;
  width: 100%;
}
.consult-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .consult-form {
    width: 100%;
  }
}
.intake-area {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  width: 80%;
}
.input-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .input-area {
    width: 100%;
  }
}
.consult-form input {
  border: solid black 1px;
  margin: 10px 11px;
  padding: auto;
  width: 30rem;
  height: 2rem;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .consult-form input {
    width: 100%;
  }
}
.consult-form .extended {
  display: flex;
  width: 97%;
}
.consult-form h2 {
  text-align: left;
  margin-left: 1.5rem;
  margin-bottom: 0.25rem;
  margin-top: 2rem;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .consult-form h2 {
    text-align: center;
  }
}
.consult-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 5rem;
  margin: auto;
  margin-top: 2rem;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 28px;
  background-color: #064789;
  color: #FAFAFA;
  margin-bottom: 20px;
}
.consult-button:hover {
  background-color: #FAFAFA;
  color: #064789;
  transition: 350ms;
  border: solid 1px #064789;
}
.longform {
  border: solid black 1px;
}
.carousel {
  overflow: hidden;
}
.inner {
  white-space: nowrap;
  transition: transform 0.3s;
}
.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: green;
  color: #ffffff;
}
.indicators {
  display: flex;
  justify-content: center;
}
.indicators > button {
  margin: 5px;
}
.indicators > button.active {
  background-color: green;
  color: #ffffff;
}
.dropdown {
  display: none;
  z-index: 9999;
}
#dropdown-trigger {
  position: relative;
  transition: none;
}
#dropdown-trigger:hover {
  background-color: #064789;
  color: #EBF2FA;
}
#dropdown-trigger:hover .dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  background-color: #064789;
  width: 200px;
}
.dropdown .link {
  color: #EBF2FA;
  font-size: 16px;
  text-align: left;
}
.dropdown .link:hover {
  background-color: #EBF2FA;
  color: #064789;
}
.RDCTA {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}
.RDCTA .wrapper {
  width: 80%;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .RDCTA .wrapper {
    margin: auto;
    width: 100%;
  }
}
.RDCTA .wrapper h1 {
  font-weight: 600;
  font-size: 42px;
  color: #064789;
}
.RDCTA .wrapper .content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  width: auto;
  margin: 0;
  padding: 0;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .RDCTA .wrapper .content {
    width: auto;
    margin: auto;
  }
}
.RDCTA .wrapper .description p {
  font-size: 24px;
  color: #064789;
  width: 100%;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .RDCTA .wrapper .description p {
    width: auto;
    margin: auto;
  }
}
.RDCTA .description li {
  font-family: 'Open Sans', sans-serif;
  margin: auto;
  margin-top: 10px;
  padding: auto;
  color: #064789;
}
.RDCTA .description li b {
  color: #679436;
}
.RDCTA .wrapper .content .description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: auto;
  text-align: left;
  width: 75%;
}
.RDCTA .wrapper .content .form {
  display: flex;
  flex-direction: column;
  height: auto;
  margin: 0;
  padding: 0;
}
.RDCTA .wrapper .content .form h3 {
  font-size: 24px;
  color: #064789;
}
.RDCTA .wrapper .content .form form {
  display: flex;
  flex-direction: column;
}
.RDCTA .wrapper .content .form form input {
  width: 390px;
  height: 50px;
  border: solid 1px #064789;
  border-radius: 5px;
  margin: 0.5rem 0rem;
}
.RDCTA .wrapper .content .form form button {
  width: 390px;
  height: 50px;
  background: #064789;
  color: #EBF2FA;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  margin-top: 0.5rem;
}
.RDCTA .wrapper .content .form form button:hover {
  background: #EBF2FA;
  color: #064789;
  border: solid 1px #064789;
}
.FormGroup {
  width: 60%;
  margin: 10px 0;
  padding: 0;
  border-style: none;
  background-color: #ffff;
  will-change: opacity, transform;
  border-radius: 4px;
  align-self: center;
  border: solid 1px black;
}
.FormRow {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}
.StripeElement--webkit-autofill {
  background: transparent !important;
}
.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}
.stripe-button {
  display: block;
  font-size: 16px;
  width: 60%;
  height: 40px;
  margin: 0 0 10px 0;
  background-color: #000;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #ffb9f6;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow;
  border: none;
  align-self: center;
}
.stripe-button:active {
  background-color: #ffff;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}
.stripe-button:disabled {
  background-color: grey;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
  transform: scale(0.99);
}
.payment-form {
  position: relative;
  width: 100%;
  display: flex;
  margin: auto;
  padding: auto;
  flex-direction: column;
  justify-content: center;
}
.payment-form-container {
  position: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  padding: auto;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
}
form {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.input-form {
  position: relative;
  display: flex;
  width: 100%;
}
.input {
  border: solid 1px black;
  margin-top: 1%;
}
.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 1%;
  margin: auto;
  padding: auto;
}
.info p {
  font-weight: 600;
}
label {
  color: #868484;
  font-family: "ruda", sans-serif;
  font-weight: 500;
  margin: 5px 0;
}
input {
  border: #c5c5c5 solid 1px;
  border-radius: 1%;
  height: 30px;
}
.stateSelect {
  width: 40%;
  margin: auto;
  margin-top: 1%;
  height: 50px;
}
.FormGroup {
  width: 40%;
}
.stripe-button {
  width: 40%;
  background-color: #064789;
  color: #FAFAFA;
  transition: 350ms;
}
.stripe-button:hover {
  background-color: #EBF2FA;
  color: #064789;
  border: #064789 0.5px solid;
}
.ReleaseForm {
  margin: 5% 0;
  padding: 1%;
  width: 60%;
  background: #fff;
}
.ReleaseForm .wrapper {
  border: #427AA1 5px solid;
  padding: 1%;
}
.releaseLogo {
  width: 100%;
  height: 100%;
  margin-bottom: 1%;
}
@media all and (max-width: 480px) and (max-width: 640px), (max-width: 850px) {
  .releaseLogo {
    display: flex;
    margin: auto;
    width: 85%;
    margin-top: 5%;
  }
}
.release svg {
  width: 100%;
  height: 100%;
  fill: none;
}
.title {
  font-family: 'Spartan', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #064789;
  margin: auto;
  padding: 0.5%;
}
.subtitle {
  font-size: 12px;
  width: 60%;
  margin: auto;
}
.content {
  width: 90%;
  margin: auto;
}
.details,
.initials {
  font-family: 'Open Sans', sans-serif;
  text-align: left;
}
.initials {
  display: flex;
  flex-direction: row;
}
.initial {
  text-align: left;
  align-self: center;
  font-size: 14px;
  margin-left: 1%;
  text-decoration: underline;
}
.signatures {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.signature {
  display: flex;
  flex-direction: row;
}
.releaseInfo,
.date {
  display: flex;
  flex-direction: row;
  width: 60%;
}
.date {
  justify-content: end;
  width: 40%;
}
.print,
.sign {
  align-self: center;
  text-align: left;
  padding: 0% 5%;
  width: 50%;
}
.timestamp {
  text-align: left;
  padding: 0% 2%;
  width: 50%;
}
.cursive {
  font-family: 'Meie Script';
  font-size: 18px;
}
.cursive2 {
  font-family: 'Comforter Brush';
  font-size: 18px;
}
.details li {
  padding: 0.5%;
}
.terms .wrapper {
  margin-top: 1%;
  margin-bottom: 5%;
  width: 60%;
  text-align: left;
}
ol,
ul {
  font-family: 'Open Sans', sans-serif;
  color: #064789;
}
.terms .wrapper {
  margin-top: 1%;
  margin-bottom: 5%;
  width: 60%;
}
.update {
  margin-top: 0;
  margin-bottom: 5%;
}
.terms .wrapper p {
  text-align: left;
  font-size: 14px;
  width: 100%;
}
.terms .wrapper ul {
  width: 100%;
}
.terms .wrapper ul li {
  width: 100%;
  text-align: left;
}
.chart {
  width: 100%;
  margin-top: 5%;
  margin-bottom: 5%;
  border: solid #064789 1px;
  color: #064789;
}
.row {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.title .col-1 {
  height: 30px;
  justify-content: center;
}
.title {
  margin: 0;
  padding: 0;
}
.title .col-2 {
  height: 30px;
  justify-content: center;
}
.title .col-3 {
  height: 30px;
  justify-content: center;
}
.col-1,
.col-2,
.col-3 {
  border: solid #064789 1px;
  font-size: 1rem;
  display: flex;
  justify-content: left;
  align-content: center;
  padding: 1%;
  height: 120px;
}
@media all and (max-width: 480px), (max-width: 640px) {
  .col-1,
  .col-2,
  .col-3 {
    font-size: 0.75rem;
  }
}
.col-1 {
  width: 20%;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}
.col-2 {
  width: 60%;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}
.col-3 {
  width: 20%;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.loading-container {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  display: flex;
  justify-content: center;
  background-color: rgba(240, 248, 255);
}
.loader-container {
  width: 200px;
  height: 500px;
  margin: auto;
  padding: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.loader {
  position: relative;
  width: 100%;
  height: 225px;
}
#rectangle {
  width: 70px;
  height: 30px;
  background: #064789;
  position: absolute;
  top: 0;
  left: 65px;
  z-index: 9997;
  border-radius: 50px;
}
#sub-rectangle {
  width: 60px;
  height: 20px;
  background: white;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 50px;
}
#vert-rectangle {
  width: 40px;
  height: 50px;
  background: #064789;
  position: absolute;
  top: 30px;
  left: 80px;
  z-index: 9999;
}
#sub-vert-rectangle {
  width: 30px;
  height: 60px;
  background: white;
  position: absolute;
  top: -5px;
  left: 5px;
  z-index: 9999;
}
#circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: absolute;
  top: 60px;
  left: 25px;
  overflow: hidden;
  border: 5px solid #064789;
  padding: 0;
}
#crop {
  width: 150px;
  height: 150px;
  background: white;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: absolute;
  top: -120px;
  left: 0px;
  z-index: 9999;
}
#crop::before {
  height: 120px;
  overflow: hidden;
}
#sub-circle {
  width: 140px;
  height: 140px;
  background: #45ff7d;
  border-radius: 50%;
  position: absolute;
  top: 0px;
  left: 0px;
}
@keyframes animateBubble {
  0% {
    margin-top: 100%;
  }
  100% {
    margin-top: -20%;
  }
}
@keyframes sideWays {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: 50px;
  }
}
.bubble {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: #e285f3;
  position: relative;
}
.x1 {
  animation: animateBubble 5s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 25%;
}
.x2 {
  animation: animateBubble 5s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 5%;
  transform: scale(0.5);
}
.x3 {
  animation: animateBubble 5s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 35%;
  transform: scale(0.7);
}
.x4 {
  animation: animateBubble 5s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 12%;
  transform: scale(0.3);
}
.x5 {
  animation: animateBubble 5s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 43%;
  transform: scale(0.5);
}
.x6 {
  animation: animateBubble 5s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 8%;
  transform: scale(0.8);
}
.x7 {
  animation: animateBubble 5s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 85%;
  transform: scale(0.4);
}
.x8 {
  animation: animateBubble 5s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 2%;
  transform: scale(0.3);
}
.x9 {
  animation: animateBubble 5s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 29%;
}
.x10 {
  animation: animateBubble 5s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 90%;
  transform: scale(0.3);
}
.loading-message {
  margin-top: 5px;
  font-size: 36px;
  text-align: right;
}
.hidden {
  display: none;
}
.markdown p {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  display: flex;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}
.markdown p p {
  text-align: left;
}
.markdown img {
  display: flex;
  max-width: 500px;
}
.blog {
  margin-bottom: 5%;
}
.post {
  border-bottom: solid 2px #064789;
}
.markdown li {
  text-align: left;
  font-size: 16px;
  margin-top: 1%;
  margin-bottom: 1%;
}
#img-icon {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}
.imageIcon {
  padding-top: 2%;
}
.imageIcon:hover {
  cursor: pointer;
}
.icon-wrapper {
  border: 1px solid #F1F1F1;
  padding: 5px;
  width: 800px;
  height: 30px;
  border-radius: 2px;
  margin: 0 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  background: white;
  text-transform: capitalize;
}
.icon-wrapper:hover {
  cursor: pointer;
  background-color: #fff;
  box-shadow: 1px 1px 1px rgba(33, 33, 33, 0.2);
}
.icon-wrapper h3 {
  width: auto;
  padding-right: 1%;
}
